@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
}

.ant-typography img {
  max-width: 100% !important;
}

#newsItems {
  display: grid;
  gap: 20px;
  padding-top: 20px;

  @media (min-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .frontpageNews {
    max-height: 47px;

    p {
      margin-bottom: 1px;
    }
  }
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .ant-menu {
    display: none !important;
  }
}

h1 {
  font-size: 28px;
}

.pageWrapper {
  display: flex;
  justify-content: center;
}

.page {
  padding: 50px;
  max-width: 1200px;
  flex: 1;
}

.header {
  height: 84px;
  z-index: 1;
  background: $primary-color; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    $secondary-color,
    $primary-color
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    $secondary-color,
    $primary-color
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  .logo {
    width: 57px;
    height: 64px;
    background-size: contain;
    margin: auto;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 6px;
  }

  .main_menu {
    line-height: 62px !important;
    margin-left: 58px !important;
    display: flex;
    background: none;
  }

  .ant-menu-submenu {
    padding: 0 !important;
  }

  .ant-menu-overflowed-submenu {
    margin-left: auto;
  }

  .hide-group-title {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

.frontpage_map {
  .ant-card-body {
    padding: 0;
  }

  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  border: 1px solid #c6e0f3;
}

.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}

.contentCard {
  border: none;
  ul {
    margin-left: 30px;
  }

  li {
    list-style-type: initial;
  }
}

.ant-card-head {
  background: $primary-color;
  color: #fff;
}

.main_cards_frontpage {
  display: flex;
  justify-content: center;
  color: #fff;

  .ant-card {
    max-width: 500px;
    margin: 10px;
    width: 100%;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    background: rgba(255, 255, 255, 0.8);

    .ant-card-head {
      background: inherit;
      color: inherit;
    }
  }
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.frontMainContent {
  margin: auto;
  max-width: 1300px;
  display: flex;
  gap: 20px;
}

@media (max-width: 900px) {
  .main_cards_frontpage {
    flex-direction: column;
    align-items: center;
  }

  .frontMainContent {
    flex-direction: column;
  }
}

.announcement {
  margin-bottom: 10px;
  padding: 20px;
  color: #484848;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  border: 1px solid #006cb529;
  background: #ecf7ff;
  display: block;
}

.newsThumb {
  background: #ecf7ff;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #dddddd;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  border: 1px solid #006cb529;
}

.staff {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 355px));
  gap: 15px;
  text-align: center;

  .person {
    background: #ecf7ff;
    padding: 20px;
    border-radius: 5px;
    max-width: 350px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    border: 1px solid #006cb529;
    height: 100%;

    .staffImg {
      padding-bottom: 100%;
      background-size: cover;
      background-color: #ddf1ff;
    }
  }
}

@media (max-width: 768px) {
  .mobile-menu-visible .ant-menu {
    top: 84px !important;
    display: inline-table !important;
  }
}

.header-title {
  color: #fff;
  position: absolute;
  left: 110px;
  top: 10px;
  font-size: 18px;
  font-weight: lighter;

  @media (max-width: 768px) {
    display: none;
  }
}

.mobileMenuButton {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.ant-typography p {
  margin-bottom: 2.5em;
}
